.alerts-container {
    position: absolute;
    right: 0;
    left: 0;
}

.alert-notification-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    max-height: 6rem;
    width: fit-content;
    min-width: 20rem;
    max-width: 28rem;
    margin: auto;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
    z-index: 1050;
    box-shadow: 2px 8px 12px #00000040;
    cursor: pointer;
    overflow-wrap: anywhere;
}

.alert-notification-container .icon-container,
.alert-notification-container .close-button  {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.alert-notification-container .message-container {
    text-align: center;
    padding: 0.25rem;
}

/* ANIMATIONS */

/* Entrada do item - step 1 */
.alert-notification-enter {
    opacity: 0;
}
/* Entrada do item - step 2 */
.alert-notification-enter.alert-notification-enter-active {
    opacity: 1;
    transition: opacity 180ms ease-in;
}

/* Saída do item - step 1 */
.alert-notification-leave {
    top: 0;
}
/* Saída do item - step 2 */
.alert-notification-leave.alert-notification-leave-active {
    top: -20rem;
    transition: top 300ms cubic-bezier(0.65, 0.05, 1, 0.25);
}

/* screen md */
@media (min-width: 768px) {
    .alert-notification-container {
        max-width: 36rem;
    }
}