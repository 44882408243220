
.login-form-container {
    margin-top: 4%; 
}

.login-form-container > h4,
.login-form-container > .text-explain {
    text-align: center;
}

.login-form-container .button-submit {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    width: 100%;
}

.login-form-container .button-submit:hover,
.login-form-container .button-submit:focus,
.login-form-container .button-submit:active {
    background-color: #4e50ad !important;
    border-color: #4e50ad !important;
}

.login-form-container .button-submit:focus {
    box-shadow: 0 0 0 0.2rem rgb(98 101 234 / 50%) !important;
}

.login-form-container .links-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 1rem;
}

.login-form-container .links-container > a {
    color: var(--primary) !important;
    text-align: center;
    margin-top: 0.5rem;
}

.button-google-login {
    background-color: #fff !important;
    color: #737679 !important;
    border-top: 1px solid rgba(230, 230, 230, 0.5) !important;
    border-left: 1px solid rgba(230, 230, 230, 0.5) !important;
    border-right: 1px solid rgba(230, 230, 230, 0.5) !important;
    border-bottom: 1px solid #737679 !important;
    font-weight: bold;
    margin-top: 16px;
    width: 100%;
}

.button-google-login:hover {
    border-bottom: 1px solid var(--primary) !important;
    color: var(--primary) !important;

}

.button-google-login:hover #icone-google{
    fill: var(--primary)
}

@media (min-width: 768px) {
    .login-form-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        width: 30%;
        max-width: 550px;
        min-width: 475px;
        padding: 2rem;
        border: 1px solid lightgrey;
        border-radius: 8px;
        box-shadow: 2px 2px 20px rgba(100,100,100,0.5);
    }
}