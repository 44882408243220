.project-grafico {
    flex-basis: 300px;
    flex-grow: 1;
    height: 100%;
    margin-top: 18px;
}

/* .selectComparative.selectbox {
    display: flex;
    flex-basis: 300px;
    flex-grow: 1;
    margin-top: 15px;
    margin-bottom: 16px;
    justify-content: flex-end;
}

.selectComparative.selectbox h5 {
    margin: 8px 16px;
}

.selectComparative.selectbox select {
    padding: 8px 16px;
    border-radius: 16px;
    border: 1px solid #555;
    color: #555;
    background: transparent;
}

.selectComparative.selectbox select:focus {
    outline: none;
}

.selectComparative.selectbox select:active {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.selectComparative.selectbox select option {
    background: whitesmoke;
    color: #555;
} */
