.popup-style {
    position: absolute;
    top: -40%;
    left: -600%;
    transform: translateX(-82%);
    background-color: #e9e9e9;
    color: #212529;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 5px 10px;
    z-index: 1000;
    white-space: nowrap;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: auto;
    font-weight: normal;
}

.hover-popup {
    position: absolute;
    top: 50px;
    left: 0;
    transform: translateX(13%);
    padding: 5px 10px;
    background-color: #e9e9e9;
    color: #212529;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    white-space: wrap;
    z-index: 10 !important;
    transition: 1s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 50%;
}

.hover-popup > {
    margin: 5px;
}