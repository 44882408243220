
.layout-wrapper {
    --sidemenu-height: calc(100vh - var(--header-height));
    --default-transition: 0.15s ease-in-out;

    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #F5F5F5;
    height: var(--sidemenu-height);
    /* min-height: var(--sidemenu-height);
    max-height: var(--sidemenu-height); */
    overflow-x: hidden;
}

.side-wrapper {
    position: fixed !important; 
    top: 0;
    bottom: 0;
    height: 100%;
    min-width: 210px;
    max-width: 210px;
    background-color: #FEFEFE;
    padding: 0 !important;
    padding-bottom: var(--header-height) !important;
    margin-left: -312px;
    transition: var(--default-transition);
    z-index: 1200;
}

.side-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.75);
    transition: var(--default-transition);
    z-index: 1199;
}

.main-wrapper {
    transition: var(--default-transition);
}

/* SHOW / HIDE SIDEMENU E OVERLAY */
    .layout-wrapper aside.hide {
        margin-left: -210px;
    }
    .layout-wrapper aside.show {
        margin-left: 0px;
    }
    .layout-wrapper main.hide {
        margin-right: 0px;
    }
    .layout-wrapper main.show {
        margin-right: -210px;
    }
    .side-overlay.show {
        display: block;
    }
    .side-overlay.hide {
        display: none;
    }

/* Nav.Item */
    .side-nav {
        height: max-content;
        background-color: #FEFEFE;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .side-item a {
        text-decoration: none;
        color: var(--text-primary);
    }

    .side-toggle {
        position: absolute;
        border-radius: 0 20% 20% 0 !important;
        padding: 0.1rem !important;
        right: -1.1rem;
        top: 4rem;
        z-index: 999;
        opacity: 0.75;
    }
    .side-toggle:hover {
        opacity: 1;
    }

/* CollapsibleMenuItem */
    .collapsible-menu-title {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        margin: 0;
        border: 0.1rem outset rgba(0,0,0,0.1);
        cursor: pointer;
    }
    .collapsible-menu-title:hover {
        opacity: 0.75;
        background-color: rgba(240, 240, 240, 0.9);
    }
    .collapsible-menu-items {
        border: 0.1rem rgba(0,0,0,0.1);
        border-style: inset;
        /* box-shadow: -4px 1px 2px grey; */
    }
    .collapsible-menu-items a {
        opacity: 0.75;
        transition: var(--default-transition);
    }
    .collapsible-menu-items a:hover {
        background-color: var(--primary);
        opacity: 0.5;
        color: #FFF;
    }
    .collapsible-menu-items .active {
        background-color: var(--primary);
        color: #FFF;
    }

.side-header {
    border-bottom: 1px solid rgb(211 211 211 / 70%);
    display: none;
}

/* screens sm */
@media (min-width: 576px) { }

/* screens md */
@media (min-width: 768px) {
    .layout-wrapper {
        height: 100vh;
    }
    .side-wrapper {
        max-width: 200px !important;
        min-width: 200px !important;
        min-height: 100vh;
        padding-bottom: 0 !important;
        z-index: 2;
    }
    .side-wrapper, aside.hide {
        margin-left: 0px !important;
        display: flex;
    }
    .side-overlay {
        display: none !important;
    }
    .main-wrapper {
        margin-left: 200px !important;
    }

    /* SHOW / HIDE */
    .layout-wrapper aside.hide,
    .layout-wrapper aside.show {
        margin-left: 0px;
    }
    .layout-wrapper main.hide, 
    .layout-wrapper main.show {
        margin-right: 0px;
    }
    .side-header {
        display: block;
    }
}

/* screen xl */ 
@media (min-width: 1200px) {
    .side-wrapper {
        max-width: 230px !important;
        min-width: 230px !important;
    }
    .main-wrapper {
        margin-left: 230px !important;
    }
}
