.loading-progress {
    height: 2rem;
    margin: 0.5rem;
}

.loading-progress .progress {
    height: inherit;
}

.loading-progress .progress-bar {
    height: inherit;
    background-color: #6365ea !important;
    font-size: 0.85rem;
}