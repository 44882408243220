/* ----- LOADING ----- */

.loading {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 1rem 0;
}

.loading > img {
    width: 100px;
}

.loading > p {
    text-align: center;
}
