

.fechamento-page {
    padding-bottom: 1rem;
}

.fechamento-page .appended-list {
    padding: 1rem 0px;
}

.fechamento-container {
    padding-right: 0;
    width: 100%;
}

@media (min-width: 768px) {
    .fechamento-container {
        padding-right: 200px;
    }
}

@media (min-width: 1195px) {
    .fechamento-container {
        padding-right: 240px;
    }
}

@media (min-width: 1803px) {
    .fechamento-container {
        padding-right: 250px;
    }
}

@media (min-width: 1800px) {
    .fechamento-container {
        padding-right: 0px;
    }
}
