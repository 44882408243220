
/* .form-build { } */

.form-build > h6 {
    color: #484848;
    font-weight: bold;
    margin-top: 1rem;
}

.form-build > hr {
    margin-top: 0.5rem;
}

.form-build .field-error-msg {
    color: red;
}

.form-build .field-error-highlight {
    border: red solid 0.1rem !important;
}
.form-build .field-error-highlight:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%) !important;
}

.fc-textarea-counter {
    color: grey;
    position: absolute;
    right: 5px;
}

/* DATEPICKER */
.datepicker-adapt {
    display: block;
}
.datepicker-popper {
    z-index: 2 !important;
}

/* PERCENTAGE */
.percentage-field {
    display: flex;
}
.percentage-field input {
    text-align: right !important;
}
.percentage-field span {
    margin: 0.5rem;
    margin-right: 0.75rem;
    margin-left: 0.25rem;
}

/* CUSTOM SWITCH */
/* 
.custom-switch {
    padding-left: 2.25rem;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.custom-switch .custom-control-label:before {
    left: -2.25rem;
    width: 3rem;
    height: 1.5rem;
    pointer-events: all;
    border-radius: 0.75rem;
}

.custom-switch .custom-control-label:after {
    top: calc(0.35rem + 0.1rem);
    left: calc(-2.25rem + 0.1rem);
    width: calc(1.25rem - 0.1rem);
    height: calc(1.25rem - 0.1rem);
    background-color: #adb5bd;
    border-radius: 50%;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
 */