
.confirm-container {
    margin-top: 4%; 
}

.confirm-container > h4,
.confirm-container > .text-explain {
    text-align: center;
}

.confirm-container .success-container {
    text-align: center;
}

.confirm-container .thumbs-up-icon {
    font-size: 40px;
}

.confirm-btn-container {
    width: 100%;
    margin: 0 !important;
}

.confirm-btn-container > .confirm-button {
    width: 100%;
    margin: 0.25rem;
}

@media (min-width: 768px) {
    .confirm-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        width: 30%;
        max-width: 550px;
        min-width: 475px;
        padding: 2rem;
        border: 1px solid lightgrey;
        border-radius: 8px;
        box-shadow: 2px 2px 20px rgba(100,100,100,0.5);
    }
}