.separate-container {
    display: flex;
    flex-direction: column;
}
.separate-container .separate-radio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.separate-container .separate-radio .projectNumber {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.separate-container .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
}
