.dashboard .section.subsection {
    display: flex;
    height: 23vh;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding: 15px;
    margin: 0 auto 15px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.section.subsection .title {
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 22px;
}
.subsection .info {
    flex-basis: 120px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.subsection .info svg {
    height: auto;
    width: 25px;
    margin-right: 10px;
    color: whitesmoke;
    border-radius: 50%;
}

.info.up svg {
    background-color: #00ac23;
}

.info.down svg {
    background-color: #e98800;
}

.subsection .info h4 {
    font-size: 18px;
}

.subsection .info p {
    font-size: 18px;
    align-items: center;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .dashboard .section.subsection {
        height: auto;
        max-width: 540px;
    }
}
