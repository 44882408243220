.uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploader > label {
    padding: 0.75rem;
    height: 150px;
    width: 150px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #6365ea;
    color: #6365ea;
    transition: all 200ms ease-in-out;
}

.uploader > label:hover {
    /* opacity: 0.85; */
    color: #FFFFFF;
    background-color: #6365ea;
    transition: all 200ms ease-in-out;
}

.uploader > label > svg {
    height: 36px;
    width: 36px;
    margin: 4px;
}

.uploader > label > span {
    text-align: center;
}

.uploader > input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.uploader > span {
    text-align: center;
    font-weight: lighter;
    font-style: italic;
    max-width: 200px;
    color: gray;
}
