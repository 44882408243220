.graph-container {
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
    padding: 15px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}
.graph-container .graph-title {
    font-size: 22px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.graph-container .graph-projets {
    font-size: 17px;
}
.graph-container .graph {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1100px) {
    .graph-container {
        flex-direction: column;
    }
}
