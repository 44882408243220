div.report-viewer {
    overflow-x: scroll;
}

div.print-bar {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
}

div.report-container {
    text-align: justify;
    top: 0;
    bottom: 0;
    margin: 0px auto;
    width: 270mm;
    min-height: 280mm;
    padding: 10mm;
    box-shadow: 4px 4px 16px rgb(100 100 100 / 75%);
    background-color: white;
}

/* AO IMPRIMIR DOCUMENTO */
@media print {
    /* REMOVENDO ITENS DA PÁGINA */
    aside,
    div.page-head,
    div.print-bar {
        display: none !important;
    }

    div.report-viewer {
        overflow-x: hidden;
    }

    div.report-container {
        box-shadow: none;
        padding: 0;
        margin: 0;
        width: auto;
        min-height: auto;
    }
    
    main.main-wrapper {
        margin-left: 0 !important;
        background-color: white !important;
    }
}