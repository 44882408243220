.graph.container {
    display: flex;
    width: auto;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding: 15px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.graph.container .title {
    flex-grow: 1;
    flex-basis: 100%;
    font-size: 22px;
    margin-bottom: 30px;
}

.graph.container .title svg {
    color: whitesmoke;
    padding: 2px;
    transform: scale(1.25);
    margin: 0 15px;
    border-radius: 25%;
}

.custom-tooltip {
    background-color: #FFF;
    padding: 18px;
    text-align: center;
}
.custom-tooltip .label {
    font-size: 18px;
}
