.button-info {
    border: none;
    background-color: transparent;
    margin-left: 0.5rem;
}

.description-text-info {
    text-align: justify;
}

.icon-arrow-left{
    display: flex;
    justify-content: flex-start;
}

.icon-arrow-right{
    display: flex;
    justify-content: flex-end;
}
