/* Global CSS variables
  @see https://developer.mozilla.org/pt-BR/docs/Web/CSS/Using_CSS_custom_properties
*/
:root {
  --main-color: #0086df;
  --primary: #6265EA !important;
  --secondary: #77C3D0 !important;

  --header-height: 72px;
  --header-color: #61dafb;
  --header-color2: #b0ebfc;

  --text-primary: #000000;
  --text-secondary: #303030;
  --text-tertiary: #505050;
  --text-quaternary: #707070;

  --default-shadow: 0px 0px 16px 0px rgb(0 0 0 / 0.85);
}

html {
  font-size: 90%;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: lighter;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.app {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* SELEÇÃO DE TEXTO CUSTOMIZADA */
::selection {
  /* WebKit/Blink Browsers */
  background: #a0a1ec;
}
::-moz-selection {
  /* Gecko Browsers */
  background: #a0a1ec;
}

#tabela-personalizada-relatorio-capacidade {
  width: calc(100vw - 260px);
  overflow-x: auto;
}

#tabela-personalizada-relatorio-capacidade .fixed-column{
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: #f5f5f5;
  margin-right: -2px;
}

#tabela-personalizada-relatorio-capacidade .fixed-column::after {
  content: '';
  position: absolute;
  top: 0;
  right: -3px;
  width: 6px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: 9;
  pointer-events: none;
}

#tabela-personalizada-relatorio-capacidade tbody tr:nth-child(odd) .fixed-column {
  background-color: #e9e9e9;
}

#tabela-personalizada-relatorio-capacidade tbody tr:nth-child(even) .fixed-column {
  background-color: #f5f5f5;
}

#tabela-personalizada-relatorio-capacidade .no-wrap {
  white-space: nowrap;
}

#tabela-personalizada-relatorio-capacidade tr:hover,
#tabela-personalizada-relatorio-capacidade tr:hover .fixed-column{
  background-color: #d8d8d8 !important;
}

#tabela-personalizada-relatorio-capacidade tr:hover
.azul-claro-realizado,
#tabela-personalizada-relatorio-capacidade tr:hover
.verde-claro-planejado,
#tabela-personalizada-relatorio-capacidade tr:hover
.cinza-claro-variacao{
  background-color: #d8d8d8 !important;
}

#tabela-personalizada-relatorio-capacidade .azul-claro-realizado{
  background-color: #e1eaf3;
}

#tabela-personalizada-relatorio-capacidade .verde-claro-planejado{
  background-color: #e5f3ec;
}

#tabela-personalizada-relatorio-capacidade .cinza-claro-variacao{
  background-color: #e9e9e9;
}

#tabela-meus-relatorios-de-margem .table-responsive{
  overflow-x: hidden !important;
}

#responsive-grafic-recharts {
  margin-top: 50px;
}
