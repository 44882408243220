
.page-container {
  padding: 1rem 0 !important;
  margin: 0 !important;
}

.page-container .page-head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.page-container .page-head .page-head-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-container .page-content {
  margin: 0;
  padding: 0;
}

/* screens md */
/* @media (min-width: 768px) {
  .page-container .page-head {
    flex-direction: row;
  }
} */

/* screen lg */
@media (min-width: 992px) {
  .page-container .page-head {
    flex-direction: row;
  }
}
