
.content-container {
  padding: 1rem 0 !important;
  margin: 0 !important;
}

.content-container .content-head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.content-container .content-body {
  margin: 0;
  padding: 0;
}

/* screen lg */ 
@media (min-width: 992px) {
  .content-container .content-head {
    flex-direction: row;
  }
}