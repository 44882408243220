.dashboard.container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    max-height: 100%;
    margin: unset;
    padding: unset;
}

.dashboard .section {
    flex-basis: 300px;
    flex-grow: 1;
    height: 100%;
    margin: 5px 10px;
}

.grafico2.selectbox {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.grafico2.selectbox h5 {
    margin: 8px 16px;
}

.grafico2.selectbox select {
    padding: 8px 16px;
    border-radius: 16px;
    border: 1px solid #555;
    color: #555;
    background: transparent;
}

.grafico2.selectbox select:focus {
    outline: none;
}

.grafico2.selectbox select:active {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.grafico2.selectbox select option {
    background: whitesmoke;
    color: #555;
}

.divCustoTotal {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 2px 8px 0 rgb(31 38 135 / 37%);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.graficosBarras {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 1100px) {
    .graficosBarras {
        grid-template-columns: 1fr;
    }
}
