/* LIST TOPBAR CSS */

.list-topbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.list-topbar *:focus {
    outline: 0;
    border-color: var(--text-tertiary) !important;
    box-shadow: 0 0 0 0.2rem rgba(56, 56, 56, 0.25) !important;
}


/* ACTIONS BTNGRP */
    .list-topbar .actions {
        margin-bottom: 1rem;
        width: 100%;
    }

    .list-topbar .actions > .btn-grp {
        width: 100%;
        justify-content: center;
    }

    .list-topbar .actions > .btn-grp .select-container {
        display: flex;
    }


/* FILTER COMPONETS */
    .list-topbar .filter {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .list-topbar .filter > .filtered-msg {
        color: var(--danger);
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .list-topbar .filter > .filtered-msg > span {
        font-style: italic;
        margin-right: 1rem;
    }

    .list-topbar .filter-form {
        display: flex;
        flex-direction: row;
        height: fit-content;
    }

/* screen md */
@media (min-width: 768px) {
    .list-topbar {
        flex-direction: row;
        justify-content: center;
    }

    .list-topbar .actions {
        margin-bottom: 0;
        width: auto;
    }

    .list-topbar .actions > .btn-grp {
        width: auto;
    }

    .list-topbar .filter {
        width: auto;
        margin-left: auto;
        flex-direction: column;
        justify-content: flex-end;
    }

    .list-topbar .filter > .filtered-msg {
        width: auto;
    }
}

/* screen lg */ 
@media (min-width: 992px) {
    .list-topbar {
        flex-direction: row;
        justify-content: flex-end;
    }
    .list-topbar .filter {
        width: auto;
        margin-left: auto;
        flex-direction: row;
        justify-content: flex-end;
    }
}