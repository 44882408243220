
.header-custom {
    background-color: var(--header-color2);
    z-index: 999;
}

.header-collapse {
    justify-content: flex-end;
    color: inherit;
}
.header-collapse a {
    text-decoration: none;
    color: inherit;
}

.navbar-logo {
    margin: 0 !important;
    padding: 0 !important;
}

.nav-avatar {
    margin: 0 0.4rem;
    cursor: pointer;
}

/* screens md */
@media (min-width: 768px) {
    .header-custom {
        display: none !important;
    }
}
