
.forgot-container {
    margin-top: 4%; 
}

.forgot-container > h4,
.forgot-container > .text-explain {
    text-align: center;
}

.forgot-container .success-container {
    text-align: center;
}

.forgot-container .thumbs-up-icon {
    font-size: 40px;
}

.forgot-btn-container {
    width: 100%;
}

.forgot-btn-container > .forgot-button {
    width: 100%;
    margin: 0.25rem;
}

.forgot-btn-container > .forgot-button.button-recover {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.forgot-btn-container > .forgot-button.button-recover:hover,
.forgot-btn-container > .forgot-button.button-recover:focus,
.forgot-btn-container > .forgot-button.button-recover:active {
    background-color: #4e50ad !important;
    border-color: #4e50ad !important;
}

.forgot-btn-container > .forgot-button.button-recover:focus {
    box-shadow: 0 0 0 0.2rem rgb(98 101 234 / 50%) !important;
}

@media (min-width: 768px) {
    .forgot-container {
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        width: 30%;
        max-width: 550px;
        min-width: 475px;
        padding: 2rem;
        border: 1px solid lightgrey;
        border-radius: 8px;
        box-shadow: 2px 2px 20px rgba(100,100,100,0.5);
    }
}