/* LIST LAYOUT CSS */

/* Aplicado ao <th> */
.list-header:hover {
    text-decoration: underline;
}

.option-filter {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.select-item {
    width: 20% !important;
}

.select-item__ano {
    width: 10% !important;
}

.cursor-pointer-row{
    cursor: pointer;
}

@media (max-width: 768px) {
    .option-filter {
        flex-direction: column !important;
        width: 100%;
    }

    .select-item {
        width: 100% !important;
    }
}
