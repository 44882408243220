
.avatar-toggle {
    display: flex;
    align-items: center;
}

.avatar-toggle:hover {
    opacity: 0.9;
}

.avatar-toggle .avatar-img {
    height: 48px;
    background-color: #fff;
}

.avatar-toggle .avatar-user {
    display: none;
    margin: 0px 0.5rem;
}

.avatar-menu {
    margin: 10px 0px !important;
    padding: 0.5rem 0px !important;
    position: absolute !important;
}

.avatar-menu .avatar-menu-item:hover, 
.avatar-menu .avatar-menu-item:active {
    color:#fff !important;
    text-decoration: none !important;
    background-color: var(--primary) !important;
}

.avatar-menu .avatar-menu-item:focus {
    box-shadow: 0 0 0 0.2rem rgb(98 101 234 / 50%) !important;
}

.avatar-menu .avatar-menu-item .menu-item-icon {
    padding-right: 0.25rem;
}
.avatar-menu .avatar-menu-item .menu-item-label {
    padding-left: 0.25rem;
}

/* screens md */
@media (min-width: 768px) {
    .avatar-toggle .avatar-user {
        display: flex;
        flex-direction: column;
    }
}