/* ESTILOS PARA PAGE RELATORIOS MAIN PAGE */

header.relatorios-main {
    display: flex;
    justify-content: center;
    position: relative;
}

header.relatorios-main div.input-group {
    width: 100%;
}

header.relatorios-main span.input-group-text {
    cursor: pointer;
}

section.relatorios-main {
    padding: 1rem 0px;
}

section.relatorios-main div.nav-container {
    display: flex;
    flex-direction: column;
}

section.relatorios-main div.nav-item {
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;
    color: #6265ea;
}

section.relatorios-main svg {
    margin: auto 0px;
    cursor: pointer;
}

section.relatorios-main a.nav-link {
    display: inline;
    color: #6265ea !important;
}

div.nenhum-relatorio {
    padding: 1rem 0px;
}

.hover-popup {
    position: absolute;
    top: 50px;
    left: 0;
    transform: translateX(13%);
    padding: 5px 10px;
    background-color: #e9e9e9;
    color: #212529;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    white-space: wrap;
    z-index: 10 !important;
    transition: 1s ease-in-out;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 50%;
}

.hover-popup > {
    margin: 5px;
}

.logoIaChat {
    position: absolute;
    right: 20px;
    cursor: pointer;
    transition: transform 0.8s ease-in-out;
}


.logoIaChat > img {
    height: 48px;
    border-radius: 100px;
}

.logoIaChat > img.hovered {
    transform: scale(1.2);
}

.messageVee {
    position: absolute;
    transform: translate(-103%, -170%);
    text-wrap: nowrap;
    background-color:  #c6e3fa;
    color: black;
    padding: 10px;
    border-radius: 10px 10px 0 10px;
    animation: fadeIn 1s ease;
}

.messageVee p {
    margin: 0;
}

/* Animação de fade-in */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.chatIA {
    position: absolute;
    height: 430px;
    width: 400px;
    right: 10px;
    bottom: 65px;
}

.chatIA>header {
    border-radius: 15px 15px 0 0;
    width: 400px;
    background-color: #c6e3fa;
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatIA>header > h5 {
    margin: 0;
}

.chatIA>header > img {
    height: 48px;
    border-radius: 100px;
}

.chatIA>header > button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 6px;
}

.chatIA>div:last-child {
    border-radius: 0 0 15px 15px;
    padding-top: 12px;
}

.chatIA>div:last-child section {
    margin-left: 32px;
}

.chatIA>div:last-child img {
    height: 22px;
    border-radius: 100px;
    position: relative;
    left: 0;
    top: -22px;
}

/* screens md */
@media (min-width: 768px) {
    header.relatorios-main div.input-group {
        width: 50%;
    }

    section.relatorios-main div.nav-container {
        flex-direction: row;
    }
}
