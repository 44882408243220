

.imposto-page {
    padding-bottom: 1rem;
}

.imposto-page .appended-list .valor-list-col {
    padding-bottom: 1rem;
}

@media (min-width: 768px) {
    .imposto-page .appended-list {
        padding: 1rem 0;
    }
    .imposto-page .appended-list .valor-list-col {
        padding-top: 0px;
    }
}